/*###########################################################################
// Resource
//#########################################################################*/

const resource = {
	config: {
		resourceBtn: 'resourceBtn'
	},

	elements: {
		resourceBtn: null
	},

	init() {
		const self = this

		const resourceBtn = document.getElementById(self.config.resourceBtn)

		console.log(resourceBtn)

		resourceBtn.addEventListener("click", function() {
			history.back();
		});

	}
}

window.addEventListener('load', function () {
	resource.init()
})
